<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip :href="mailto(value)" target="_blank" v-bind="attrs" v-on="on">
        <Icon left>{{ icon }}</Icon>
        {{ displayTitle }}
      </v-chip>
    </template>
    <span>{{ value }}</span>
  </v-tooltip>
</template>
<script>
import { defineComponent } from "vue";
import { mailto } from "common/utils/helper.js";

export default defineComponent({
  name: "EmailChip",
  props: {
    value: { type: String, default: "" },
    title: { type: String, default: "" },
    icon: { type: String, default: "mdi-email" },
  },
  computed: {
    displayTitle() {
      return this.title || this.value;
    },
  },
  methods: {
    mailto,
  },
});
</script>
