<template>
  <v-card v-if="isVisible" v-bind="$attrs">
    <v-system-bar>Kontakt per E-Mail</v-system-bar>
    <v-card-text>
      <v-chip-group column>
        <EmailChip
          v-for="(distributionList, index) in distributionLists"
          :key="index"
          :title="distributionList.description"
          :value="distributionList.emails.join(',')"
        />
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import EmailChip from "common/components/EmailChip.vue";

export default defineComponent({
  name: "ContactEmail",
  components: { EmailChip },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      distributionLists: [],
    };
  },
  computed: {
    hasPerson() {
      return this.person && this.person.id;
    },
    isVisible() {
      return this.hasPerson && this.distributionLists.length > 0;
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.distributionLists = [];
      if (!this.hasPerson) return;
      this.distributionLists = await this.apiGet({
        resource: "person/email",
        id: this.person.id,
      });
    },
  },
});
</script>
